import {Stats} from './types';

export const getBaseStats = (): Stats => {
    return {
        daysPlayed: 0,
        daysSolved: 0,
        currentStreak: 0,
        maxStreak: 0,
        hardestSolve: {
            0: 0,
            3: 0,
            6: 0,
            9: 0,
        }
    };
}

export const getNumberWithOrdinalSuffix = (input: number): string => {
    const j = input % 10;
    const k = input % 100;
    if (j == 1 && k != 11) {
        return input + "st";
    }
    if (j == 2 && k != 12) {
        return input + "nd";
    }
    if (j == 3 && k != 13) {
        return input + "rd";
    }
    return input + "th";
}
