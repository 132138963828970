import React, {useEffect, useState} from 'react';
import {Close} from '../images/Close';
import {Spinner} from './Spinner';
import {StarGroup} from './StarGroup';
import {getMyScores} from '../api/api';
import {getSavedData} from '../common/savedData';

type MyScoresModalProps = {
    onClose: () => void;
}

// Array.apply(null, { length: 10 }).map((e, i) => (
//     <span className="busterCards" key={i}>
//         ♦
//     </span>
// ));

export const MyScoresModal = ({onClose}: MyScoresModalProps) => {
    const savedData = getSavedData();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        (async () => {
            const myScores = await getMyScores(savedData.userId);
            console.log('myScores: ', myScores);
        //     const response = (await(await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)).json());
        //     console.log('response: ', response);
            setIsLoading(false);
        })();
    }, []);
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_star.png" />
                    <span>My Scores</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                {isLoading
                    ? <div className="definitionHeader">Loading your scores. Just a sec. <Spinner /></div>
                    : (
                        <>
                            <h3>Today (6/10)</h3>
                            <div className="starGroup">
                                <StarGroup filled={6} empty={4} />
                            </div>
                            <h3>This Week (15/70)</h3>
                            <h4 className="scoresLabel">Sunday (9/10)</h4>
                            <div className="starGroup overlapping">
                                <StarGroup filled={9} empty={1} />
                            </div>
                            <h4 className="scoresLabel">Monday (6/10)</h4>
                            <div className="starGroup overlapping">
                                <StarGroup filled={6} empty={4} />
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}
