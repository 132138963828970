import React from "react";

export const Circle = ({fillPercent}: {fillPercent: number}) =>
    <span className="bordered-circle-wrapper">
        <svg viewBox="0 0 36 36" className="bordered-circle">
            <path className="circle" strokeDasharray={`${fillPercent}, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
        </svg>
        <span>{fillPercent}%</span>
    </span>
