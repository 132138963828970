import React from 'react';

type StarGroupProps = {
    filled: number;
    empty: number;
}

const repeat = (element: JSX.Element, length: number) =>
    [...Array(length)].map((e, index) => React.cloneElement(element, { key: index }));

export const StarGroup = ({filled, empty}: StarGroupProps) => {
    const width = (filled + empty - 1) * 32 + 64;
    return (
        <div className="starGroup" style={{width}}>
            {repeat(<img width="64" height="64" src="https://wordcrun.ch/img/icon_star.png" />, filled)}
            {repeat(<img width="64" height="64" src="https://wordcrun.ch/img/icon_star_dim.png" />, empty)}
        </div>
    );
}
