import React, {useRef} from 'react';

type HeaderProps = {
    openMenu: () => void;
    openWhatIsNewModal: () => void;
    viewedNewsIndex?: number;
}

export const NEWS_INDEX = 1;

export const Header = ({openMenu, openWhatIsNewModal, viewedNewsIndex}: HeaderProps) => {
    const titleRef = useRef<HTMLHeadingElement>(null);
    const crunch = () => {
        if (!titleRef.current) {
            return;
        }
        titleRef.current.style.animation = 'none';
        titleRef.current.offsetHeight;
        titleRef.current.style.animation = '';
    }
    return (
        <div className="header-container">
            <h1 onClick={crunch} ref={titleRef} className="crunch">Word&nbsp;Crunch</h1>
            {(typeof viewedNewsIndex !== 'number' || viewedNewsIndex < NEWS_INDEX)
                && <div onClick={openWhatIsNewModal} className="new-ribbon">New!</div>}
            <img
                onClick={openMenu}
                width="256"
                height="256"
                src="https://wordcrun.ch/img/icon_settings.png"
            />
        </div>
    )
}
