export enum OPENED_MODAL {
    NONE,
    HOW_TO_PLAY,
    MENU,
    SOLUTIONS,
    MY_SCORES,
    LEADERBOARDS,
    SETTINGS,
    BATTLE,
    DEFINITION,
    WHAT_IS_NEW,
}

export type Stats = {
    daysPlayed: number;
    daysSolved: number;
    currentStreak: number;
    maxStreak: number;
    hardestSolve: Record<number, number>;
}
