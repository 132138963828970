import React from 'react';
import {Close} from '../images/Close';
import {NEWS_INDEX} from './Header';
import {updateViewedNewsIndex} from '../common/savedData';

type WhatIsNewModalProps = {
    onClose: () => void;
}

export const WhatIsNewModal = ({onClose}: WhatIsNewModalProps) => {
    updateViewedNewsIndex(NEWS_INDEX);
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_message.png" />
                    <span>What's New?</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                <ul className="whats-new-list">
                    <li>
                        <img width="64" height="64" src="https://wordcrun.ch/img/icon_rankings.png" />
                        <div>
                            <h3>New feature: Leaderboards!</h3>
                            <p>From the gear icon in the top right, click the Leaderboards option to see where you stack up against other players!</p>
                            <p><b>Optional: </b>Enter your name and location in 'Settings' for display on the Leaderboard</p>
                            <i>February 6, 2022</i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
