import React from 'react';
import { IconButton } from './IconButton';

const reload = () => {
    location.reload()
}
export const SiteUnavailable = () => (
    <div className="error-message">
        <div>
            <h3>Word Crunch is Temporarily Unavailable</h3>
            <p>Sorry about that! We'll be back up and running as soon as possible.</p>
            <p>Please click to try again.</p>
        </div>
        <div className="center"><IconButton large onClick={reload} text="Reload" icon="btn_icon_reload.png" /></div>
    </div>
)
