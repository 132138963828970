import React, {useEffect, useState} from 'react';
import {Close} from '../images/Close';
import {updateUserName} from '../api/api';
import {getSavedData, updateSavedUserName} from '../common/savedData';
import {useDebounce} from '../hooks/useDebounce';

type SettingsProps = {
    onClose: () => void;
    difficulty: number;
    setDifficulty: (difficulty: number) => void;
}

export const Settings = ({onClose, difficulty, setDifficulty}: SettingsProps) => {
    const savedData = getSavedData();
    const [userName, setUserName] = useState<string>(savedData.userName || '');
    const [saveResponseMessage, setSaveResponseMessage] = useState<string>('');
    const difficultySelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDifficulty(parseInt(event.target.value, 10));
    }

    const debouncedUserName = useDebounce(userName, 1000);

    useEffect(() => {
        (async () => {
            if (debouncedUserName && debouncedUserName !== savedData.userName) {
                let response;
                try {
                    response = await updateUserName(savedData.userId, debouncedUserName);
                    setSaveResponseMessage(`Saved your name: ${response.name}`);
                } catch (e) {
                    setSaveResponseMessage('Unable to save your name. Please try again later.');
                }
                updateSavedUserName(debouncedUserName);
            }
        })();
    }, [debouncedUserName]);
    
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_settings.png" />
                    <span>Settings</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                <div className="settings-container">
                    <div className="settings-item">
                        <h2>Current difficulty</h2>
                        <select onChange={difficultySelectOnChange} value={difficulty}>
                            <option value={9}>Easy</option>
                            <option value={6}>Medium</option>
                            <option value={3}>Hard</option>
                            <option value={0}>Brutal</option>
                        </select>
                    </div>
                    <div className="settings-item">
                        <h2>Your name </h2>
                        <div className="subtitle">(for the leaderboard)</div>
                        <input value={userName} onChange={event => {
                            setUserName(event.target.value);
                        }} />
                        {saveResponseMessage !== '' && <div>{saveResponseMessage}</div>}
                    </div>
                    <div className="settings-item">
                        <h2>Feedback</h2>
                        <a target="_blank" href="https://twitter.com/word_crunch">Twitter</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
