import React from "react";

import {months} from './constants';

type DateCalendarProps = {
    date: string;
    size?: 'small' | 'large';
};


export const DateCalendar = ({date, size = 'large'}: DateCalendarProps) => {
    const monthIndex = parseInt(date.substring(5, 7), 10) - 1;
    const day = date.substring(8, 10);
    return (
        <span className={`date-calendar ${size}`}>
            <span>{months[monthIndex]}</span>
            <span>{day}</span>
        </span>
    );
}
