import React from 'react';
import {Close} from '../images/Close';
import {Check} from '../images/Check';

type HowToPlayProps = {
    onClose: () => void;
}
export const HowToPlay = ({onClose}: HowToPlayProps) => {
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_how_to_play.png" />
                    <span>How To Play</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content how-to-play">
                <p>Guess the 5 words that make up the Word Crunch.</p>
                <p>The words go left to right, and <em><b>those same words</b></em> go top to bottom.</p>
                <p>Some letters are <b className="white-text">fixed</b> and cannot be moved.</p>
                <hr />
                <h3>Example</h3>
                <table className="grid">
                    <tbody>
                        <tr>
                            <td className="cell"><span className="letter-span fade-in">C</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-50">H</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-100">E</span></td>
                            <td className="cell fixed-cell"><span className="letter-span">S</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-200">T</span></td>
                        </tr>
                        <tr>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-50">H</span></td>
                            <td className="cell fixed-cell"><span className="letter-span">E</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-6050">L</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-3000">L</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-6150">O</span></td>
                        </tr>
                        <tr>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-100">E</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-6050">L</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-9000">B</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-3050">O</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-9100">W</span></td>
                        </tr>
                        <tr>
                            <td className="cell fixed-cell"><span className="letter-span">S</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-3000">L</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-3050">O</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-3100">P</span></td>
                            <td className="cell fixed-cell"><span className="letter-span">E</span></td>
                        </tr>
                        <tr>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-200">T</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-6150">O</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-9100">W</span></td>
                            <td className="cell fixed-cell"><span className="letter-span">E</span></td>
                            <td className="cell"><span className="letter-span fade-in fade-in-delay-12000">R</span></td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <p className="check-explanation">
                    <Check />
                    <span>A green checkmark means that the word in that row/column is a valid English word, but not necessarily the correct word that would solve the puzzle.</span>
                </p>
                <p>A new set of Word Crunch puzzles will be available every day!</p>
            </div>
        </div>
    );
}
