import React, {useEffect, useState} from 'react';
import {Close} from '../images/Close';
import {Spinner} from './Spinner';
import {DateCalendar} from './DateCalendar';
import {StarGroup} from './StarGroup';
import {getLeaderboards, LeaderboardResponse, LeaderboardResult} from '../api/api';
import {getSavedData} from '../common/savedData';
import {getNumberWithOrdinalSuffix} from '../common/util';

type LeaderboardsModalProps = {
    onClose: () => void;
}

const getTable = (data: LeaderboardResult[]) => {
    return (
        <table className="leaderboards-table">
            <thead>
                <tr>
                    <th className="leaderboards-rank">Rank</th>
                    <th className="leaderboards-name">Player Name</th>
                    <th className="leaderboards-stars"><img width="64" height="64" src="https://wordcrun.ch/img/icon_star.png" /></th>
                </tr>
            </thead>
            <tbody>
                {data.map((result: LeaderboardResult, index: number) => (
                    <tr key={index} className={result.isMe ? "me" : ""}>
                        <td className="leaderboards-rank">
                            {
                                result.rank < 4
                                    ? <img className="leaderboard-medal" height="90" width="90" src={`https://wordcrun.ch/img/ranking_medal_${result.rank}.png`} />
                                    : getNumberWithOrdinalSuffix(result.rank)
                            }
                        </td>
                        <td className="leaderboards-name">{result.name || 'Name not entered'}{result.isMe ? ' (you)' : ''}</td>
                        <td className="leaderboards-stars">{result.score}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export const LeaderboardsModal = ({onClose}: LeaderboardsModalProps) => {
    const savedData = getSavedData();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    // const [activeLeaderboard, setActiveLeaderboard] = useState<'today' | 'thisWeek'>('today');
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardResponse>();
    useEffect(() => {
        (async () => {
            const leaderboards = await getLeaderboards(savedData.userId);
            setLeaderboardData(leaderboards);
            setIsLoading(false);
        })();
    }, []);
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_rankings.png" />
                    <span>Leaderboards</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                {isLoading
                    ? <div className="definitionHeader">Loading leaderboards. Just a sec. <Spinner /></div>
                    : <div>
                            <ul className="tab-container">
                                <li className={activeTabIndex === 0 ? 'active' : ''} onClick={() => {
                                    setActiveTabIndex(0);
                                }}>Today</li>
                                <li className={activeTabIndex === 1 ? 'active' : ''} onClick={() => {
                                    setActiveTabIndex(1);
                                }}>This Week</li>
                                <li className={activeTabIndex === 2 ? 'active' : ''} onClick={() => {
                                    setActiveTabIndex(2);
                                }}>Last Week</li>
                                <li className={activeTabIndex === 3 ? 'active' : ''} onClick={() => {
                                    setActiveTabIndex(3);
                                }}>Help</li>
                            </ul>
                            {typeof leaderboardData === 'undefined'
                                ? null
                                : <>
                                    {activeTabIndex === 0 &&
                                        <div className="leaderboard-tab">
                                            <h3 className="leaderboard-heading"><DateCalendar size="small" date={leaderboardData.dateString} /></h3>
                                            {getTable(leaderboardData.todayResults)}
                                        </div>
                                    }
                                    {activeTabIndex === 1 &&
                                        <div className="leaderboard-tab">
                                            <h3 className="leaderboard-heading">Week beginning <DateCalendar size="small" date={leaderboardData.firstDayOfWeekString} /></h3>
                                            {getTable(leaderboardData.thisWeekResults)}
                                        </div>
                                    }
                                    {activeTabIndex === 2 &&
                                        <div className="leaderboard-tab">
                                            <h3 className="leaderboard-heading">
                                                <DateCalendar size="small" date={leaderboardData.firstDayOfLastWeekString} />
                                                {' '} through {' '}
                                                <DateCalendar size="small" date={leaderboardData.lastDayOfLastWeekString} />
                                            </h3>
                                            {getTable(leaderboardData.lastWeekResults)}
                                        </div>
                                    }
                                    {activeTabIndex === 3 &&
                                        <div className="leaderboard-tab">
                                            <h3 className="leaderboard-heading">How to get stars</h3>
                                            <div>
                                                Get stars by solving puzzles. Earn up to 10 stars each day!
                                                <div className="instruction">
                                                    <div>Solve <b>Easy</b> to get 1 star.</div>
                                                    <StarGroup filled={1} empty={0} />
                                                </div>
                                                <div className="instruction">
                                                    <div>Solve <b>Medium</b> to get 2 stars.</div>
                                                    <StarGroup filled={2} empty={0} />
                                                </div>
                                                <div className="instruction">
                                                    <div>Solve <b>Hard</b> to get 3 stars.</div>
                                                    <StarGroup filled={3} empty={0} />
                                                </div>
                                                <div className="instruction">
                                                    <div>Solve <b>Brutal</b> to get 4 stars.</div>
                                                    <StarGroup filled={4} empty={0} />
                                                </div>
                                            </div>
                                            <div className="instruction">
                                                <b>Optional:</b> Enter your name and location in 'Settings' for display on the Leaderboard
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                    </div>
                }
            </div>
        </div>
    );
}
