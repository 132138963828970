import {ZERO_TO_FOUR} from './constants';

export const getBlankGrid = (): Record<string, string> => ({
    '0-0': '',
    '0-1': '',
    '0-2': '',
    '0-3': '',
    '0-4': '',
    '1-0': '',
    '1-1': '',
    '1-2': '',
    '1-3': '',
    '1-4': '',
    '2-0': '',
    '2-1': '',
    '2-2': '',
    '2-3': '',
    '2-4': '',
    '3-0': '',
    '3-1': '',
    '3-2': '',
    '3-3': '',
    '3-4': '',
    '4-0': '',
    '4-1': '',
    '4-2': '',
    '4-3': '',
    '4-4': '',
});

export const getUnusedLetters = (allLetters: string[], grid: Record<string, string>): string[] => {
    const unusedLetters = [...allLetters];
    ZERO_TO_FOUR.forEach(row => {
        ZERO_TO_FOUR.forEach(column => {
            if (column >= row) {
                const letter = grid[`${row}-${column}`];
                const letterIndex = unusedLetters.indexOf(letter);
                if (letterIndex !== -1) {
                    unusedLetters.splice(letterIndex, 1);
                }
            }
        });
    });
    return unusedLetters;
}

export const difficultyLabelMap: Record<number, string> = {
    9: 'Easy',
    6: 'Medium',
    3: 'Hard',
    0: 'Brutal',
};

export const trackEvent = (action: string, category: string, label: string, value: string | number) => {
    if (typeof gtag !== 'function') {
        return;
    }
    gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
    });
}
