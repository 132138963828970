import React, {useEffect, useState} from 'react';
import {Close} from '../images/Close';
import {Spinner} from './Spinner';
import {trackEvent} from './util';

type DefinitionModalProps = {
    word: string;
    onClose: () => void;
}

type FetchedDefinition = {
    partOfSpeech: string;
    definition: string;
    example: string;
}
export const DefinitionModal = ({word, onClose}: DefinitionModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [definitions, setDefinitions] = useState<FetchedDefinition[]>([]);
    useEffect(() => {
        (async () => {
            try {
                trackEvent('fetch', 'definition', 'success', word);
                const response = (await(await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)).json());
                const fetchedDefinitions: FetchedDefinition[] = [];
                for (let i = 0; i < 2; i++) {
                    const meaning = response[0].meanings[i];
                    if (meaning) {
                        const {partOfSpeech} = meaning;
                        const {definition, example} = meaning.definitions[0];
                        if (definition) {
                            fetchedDefinitions.push({partOfSpeech, definition, example});
                        }
                    }
                }
                setDefinitions(fetchedDefinitions);
                setIsLoading(false);
            } catch (e) {
                setDefinitions([]);
                setIsLoading(false);
                trackEvent('fetch', 'definition', 'error', word);
            }
        })();
    }, [word]);
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_book.png" />
                    <span>Definition</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                <h2>{word}</h2>
                {isLoading
                    ? <div className="definitionHeader">Looking up <b>{word}</b> in the dictionary. Just a sec. <Spinner /></div>
                    : definitions.length
                        ? (
                            <ol>
                                {definitions.map(({partOfSpeech, definition, example}: FetchedDefinition, index: number) => (
                                    <li key={index}>
                                        <div>(<i>{partOfSpeech}</i>) <b>{definition}</b></div>
                                        <div></div>
                                        {example ? <p>Example: {example}</p> : null}
                                    </li>
                                ))}
                            </ol>
                        )
                        : <div>I'm sorry, something went wrong. Please try again later.</div>
                }
            </div>
        </div>
    );
}
