import React from 'react';

type IconButtonProps = {
    text: string;
    icon: string;
    large?: boolean;
    onClick: () => void;
}

export const IconButton = ({text, icon, large, onClick}: IconButtonProps) => {
    const className = large ? "icon-button large" : "icon-button";
    return (
        <button className={className} onClick={onClick}>
            <span>{text}</span>
            <img src={`https://wordcrun.ch/img/${icon}`} />
        </button>
    );
}
