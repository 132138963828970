import React, {useEffect, useState} from 'react';
import {Close} from '../images/Close';
import {Solution} from './Solution';
import {DateCalendar} from './DateCalendar';
import {getYesterdaySolutions, PuzzleSolution} from '../api/api';

type SolutionsProps = {
    onClose: () => void;
}

const labels = ['Easy', 'Medium', 'Hard', 'Brutal'];
export const Solutions = ({onClose}: SolutionsProps) => {
    const [yesterdaySolutions, setYesterdaySolutions] = useState<PuzzleSolution[]>([]);
    useEffect(() => {
        (async () => {
            const yesterdaySolutions = await getYesterdaySolutions();
            const {puzzleEasy, puzzleMedium, puzzleHard, puzzleBrutal} = yesterdaySolutions;
            setYesterdaySolutions([puzzleEasy, puzzleMedium, puzzleHard, puzzleBrutal]);
        })();
    }, []);
    return (
        <div className="modal">
            <div className="modal-header">
                <h2>
                    <img width="64" height="64" src="https://wordcrun.ch/img/icon_calendar.png" />
                    <span>Past Solutions</span>
                </h2>
                <Close onClick={onClose} />
            </div>
            <div className="modal-content">
                {
                    yesterdaySolutions.length === 0
                    ? (<div>Loading...</div>)
                    : (<>
                        <h2>Solutions For <DateCalendar date={yesterdaySolutions[0].Date} /></h2>
                        {yesterdaySolutions.map((puzzle: PuzzleSolution, index: number) =>
                            <Solution key={index} label={labels[index]} puzzle={puzzle} />
                        )}
                    </>)
                }
            </div>
        </div>
    );
}
