import React, {useEffect, useState} from 'react';
import {DateCalendar} from './DateCalendar';
import {Circle} from './Circle';
import {Validation} from './Validation';
import {IconButton} from './IconButton';
import {StarGroup} from './StarGroup';
import {difficultyLabelMap} from './util';
import {months} from './constants';
import confetti from 'canvas-confetti';
import {Stats} from '../common/types';

type VictoryProps = {
    difficulty: number;
    puzzleDate: string;
    updateDifficulty: (newDifficulty: number) => void;
    gameStats: number[];
    myStats: Stats;
}

const star = String.fromCodePoint(0x1F31F);

const phraseMap: Record<number, string> = {
    0: `Unbelievable!`,
    3: `Tremendous!`,
    6: `Awesome!`,
    9: `Nice job!`,
}

const particleMap: Record<number, number> = {
    0: 1000,
    3: 500,
    6: 200,
    9: 100,
}

const formatDate = (dateString: string) => {
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(5, 7), 10);
    const day = parseInt(dateString.substring(8, 10), 10);
    const monthString = months[month -1];
    return `${monthString} ${day}, ${year}`;
}

export const Victory = ({difficulty, puzzleDate, gameStats, myStats, updateDifficulty}: VictoryProps) => {
    const percentBetterThan = Math.floor(100 * (gameStats[1] - gameStats[0]) / gameStats[1]);
    const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
    useEffect(() => {
        confetti({
            particleCount: particleMap[difficulty],
        });
    }, []);
    const nextLevel = () => {
        updateDifficulty(difficulty - 3);
    }
    const share = async () => {
        const shareData = {
            title: 'Word Crunch',
            text: `Word Crunch ${formatDate(puzzleDate)}: ${difficultyLabelMap[difficulty]} ${star.repeat(1 + (9 - difficulty) / 3)}`
        }

        try {
            if (navigator.userAgent.toLowerCase().match(/mobile/i) && typeof navigator.share === 'function') {
                await navigator.share(shareData);
            } else {
                await navigator.clipboard.writeText(shareData.text);
                setCopiedToClipboard(true);
            }
        } catch(e: unknown) {
            //
        }
    }
    const highestSolveCount = Math.max(...Object.values(myStats.hardestSolve));
    const getBarWidth = (count: number) => 100 * count / highestSolveCount;
    const labels: Record<number, string> = {
        9: 'Easy',
        6: 'Medium',
        3: 'Hard',
        0: 'Brutal',
    };
    const bars = [9, 6, 3, 0].map(statDifficulty => {
        const count = myStats.hardestSolve[statDifficulty];
        const barWidth = getBarWidth(count);
        const showValueInsideBar = barWidth > 10;
        const streakValueClassName = barWidth === 0 ? "streak-bar zero-width" : "streak-bar";
        const rowClassName = statDifficulty === difficulty ? "current" : "";
        return (
            <tr key={statDifficulty} className={rowClassName}>
                <td className="streak-label">{labels[statDifficulty]}</td>
                <td className="streak-value"><div className={streakValueClassName} style={{width: `${barWidth}%`}}>{showValueInsideBar ? count : null}</div>{showValueInsideBar ? null : count}</td>
            </tr>
        );
    })
    return (
        <div className="victory">
            <h2>{phraseMap[difficulty]}</h2>
            <div>
                <div className="victory-main-info">
                    <div>
                        <div>You solved <b>{difficultyLabelMap[difficulty]}</b></div>
                        <div className="stars">
                            <StarGroup filled={1 + (9 - difficulty) / 3} empty={0} />
                        </div>
                    </div>
                    <DateCalendar date={puzzleDate} />
                </div>
                <p className="buttonContainer">
                {difficulty === 0
                    ? <span>You've solved all of the puzzles for today. Come back for more tomorrow!</span>
                    : <span><IconButton onClick={nextLevel} text="Next Level" icon="btn_icon_puzzle.png" /></span>
                }
                {copiedToClipboard
                    ? <span className="copied-to-clipboard-message"><Validation status="y" onClick={() => undefined} />Copied to clipboard.</span>
                    : <span><IconButton onClick={share} text="Share" icon="btn_icon_share.png" /></span>
                }
                </p>
                <div className="victoryStatsContainer">
                    <h4 className="scores-label">Stats</h4>
                    <div className="stats-container">
                        <div className="stat-container"><div className="stat-value">{myStats.daysPlayed}</div><div className="stat-label">Days Played</div></div>
                        <div className="stat-container"><div className="stat-value">{myStats.daysSolved}</div><div className="stat-label">Days With a Solve</div></div>
                        <div className="stat-container"><div className="stat-value">{myStats.currentStreak}</div><div className="stat-label">Current Streak</div></div>
                        <div className="stat-container"><div className="stat-value">{myStats.maxStreak}</div><div className="stat-label">Max Streak</div></div>
                    </div>
                    <h4 className="scores-label">Hardest Solve</h4>
                    <table className="streak-table"><tbody>
                        {bars}
                    </tbody></table>
                </div>
                <p>This puzzle has been solved by {gameStats[0]} out of {gameStats[1]} players. You've done better than {percentBetterThan}% of players.</p>
                <p><Circle fillPercent={percentBetterThan} /></p>
            </div>
        </div>
    );
}
