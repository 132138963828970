import React, {Fragment} from 'react';
import {ZERO_TO_FOUR} from './constants';
import {Validation} from './Validation';
import {Letter} from './Letter';
import {DraggableEventHandler} from 'react-draggable';

type GridProps = {
    fixedCells: Record<string, string>
    grid: Record<string, string>;
    selectedCell: number[];
    selectCell: (coordinates: number[]) => void;
    validation: string[];
    getOnStart: (row: number, column: number, letter: string) => () => void;
    onDragStop: () => void;
    onDrag: DraggableEventHandler;
    droppingCoordinates: number[];
    draggedFromCoordinates: number[];
    victorious: boolean;
    setLookupWord: (index: number) => void;
}

export const Grid = ({
    fixedCells,
    grid,
    selectedCell,
    selectCell,
    validation,
    getOnStart,
    onDragStop,
    onDrag,
    droppingCoordinates,
    draggedFromCoordinates,
    victorious,
    setLookupWord,
}: GridProps) => (
    <div className={'grid' + (droppingCoordinates[0] !== -1 && droppingCoordinates[1] !== -1 ? ' is-dropping' : ' is-not-dropping')}>
        {ZERO_TO_FOUR.map((rowIndex: number) => (
            <Fragment key={rowIndex}>
                {ZERO_TO_FOUR.map((columnIndex: number) => {
                    const letter = grid[`${rowIndex}-${columnIndex}`];
                    const isFixed = `${rowIndex}-${columnIndex}` in fixedCells;
                    const isSelected = (rowIndex === selectedCell[0] && columnIndex === selectedCell[1])
                        || (rowIndex === selectedCell[1] && columnIndex === selectedCell[0]);
                    const isDropping = (rowIndex === droppingCoordinates[0] && columnIndex === droppingCoordinates[1])
                        || (rowIndex === droppingCoordinates[1] && columnIndex === droppingCoordinates[0]);
                    const isDragging = rowIndex === draggedFromCoordinates[0] && columnIndex === draggedFromCoordinates[1];
                    return (
                        <div
                            key={`${rowIndex}-${columnIndex}`}
                            className={'cell' + (isFixed ? ' fixed-cell' : ' changeable-cell') + (isSelected && !victorious ? ' selected-cell' : '') + (isDropping ? ' dropping-cell' : '') + (isDragging ? ' dragging-cell' : '')}
                            data-rowindex={rowIndex}
                            data-columnindex={columnIndex}
                            onClick={() => {
                                if (letter === '') {
                                    selectCell([rowIndex, columnIndex]);
                                }
                            }}
                        >
                            {
                                letter === ''
                                    ? <span className="empty"></span>
                                    : (
                                        <Letter
                                            letter={letter}
                                            draggable={!victorious && !isFixed}
                                            gridRow={rowIndex}
                                            gridColumn={columnIndex}
                                            onStart={getOnStart(rowIndex, columnIndex, letter)}
                                            onStop={onDragStop}
                                            onDrag={onDrag}
                                        />
                                    )
                            }
                        </div>
                    );
                })}
                <div className="spacer">
                    <Validation
                        status={validation[rowIndex]}
                        onClick={() => {
                            setLookupWord(rowIndex);
                        }}
                    />
                </div>
            </Fragment>
        ))}
        {ZERO_TO_FOUR.map((columnIndex: number) => {
            return (
                <div key={columnIndex} className="bottom-spacer">
                    <Validation
                        status={validation[columnIndex]}
                        onClick={() => {
                            setLookupWord(columnIndex);
                        }}
                    />
                </div>
            )
        })}
    </div>
);
