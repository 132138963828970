import React, {useEffect, useState} from 'react';

type HowToPlayProps = {
    timeInfo: [Date, number];
}

const toTwoDigits = (input: number) => input.toString().padStart(2, '0');

export const TimeUntil = ({timeInfo}: HowToPlayProps) => {
    const [timeRemaining, setTimeRemaining] = useState<string>('...');
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const timeElapsed = Math.floor((now.getTime() - timeInfo[0].getTime()) / 1000);
            const difference = timeInfo[1] - timeElapsed;
            const seconds = toTwoDigits(difference % 60);
            const minutes = toTwoDigits(Math.floor(difference / 60) % 60);
            const hours = toTwoDigits(Math.floor(difference / 3600) % 24);
            setTimeRemaining(`${hours}:${minutes}:${seconds}`);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeInfo]);
    return <span>{timeRemaining}</span>;
};
