import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {App} from './App/App';

Sentry.init({
  dsn: "https://c4e5e046f8be4a36a82746d2bef5ad4e@o1123717.ingest.sentry.io/6161862",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0, // 1.0 = 100%
});

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
