import {Stats} from '../common/types';

const ENVIRONMNET_BASE_URL_MAP: Record<string, string> = {
    development: 'http://localhost:5002/',
    production: '/',
};

const BASE_URL = ENVIRONMNET_BASE_URL_MAP[process.env.NODE_ENV || 'production'];

type PuzzleData = {
    puzzleId: string;
    fixedCells: Record<string, string>;
    lettersRandom: string[];
    date: string;
    secondsUntilNewPuzzles: number;
    gameStats: number[];
    userId: string;
}

export const getPuzzle = async (difficulty: number, userId: string | undefined): Promise<PuzzleData> => {
    const headerObject: HeadersInit = {};
    if (userId) {
        headerObject.Authorization = `Basic ${userId}`;
    }
    const headers = new Headers(headerObject);
    return (await fetch(`${BASE_URL}api/v1/puzzle/wc_5_c/${difficulty}`, {headers})).json();
}

type ValidateResponse = {
    success: boolean;
    victory: boolean;
    validation: string[];
    userId: string;
    stats: Stats;
}
export const validate = async (attempt: string, userId: string | undefined, puzzleId: string): Promise<ValidateResponse> => {
    const headerObject: HeadersInit = {};
    if (userId) {
        headerObject.Authorization = `Basic ${userId}`;
    }
    const headers = new Headers(headerObject);
    return (await fetch(`${BASE_URL}api/v1/puzzle/wc_5_c/${puzzleId}/validate/${attempt}`, {headers})).json();
}

export type PuzzleSolution = {
    Id: string;
    Hints: Record<string, string>;
    Word1: string;
    Word2: string;
    Word3: string;
    Word4: string;
    Word5: string;
    Date: string;
};

type YesterdayResponse = {
    puzzleEasy: PuzzleSolution;
    puzzleMedium: PuzzleSolution;
    puzzleHard: PuzzleSolution;
    puzzleBrutal: PuzzleSolution;
}
export const getYesterdaySolutions = async (): Promise<YesterdayResponse> => {
    return (await fetch(`${BASE_URL}api/v1/puzzle/wc_5_c/solutions/yesterday`)).json();
}

export const getMyScores = async (userId: string | undefined): Promise<any> => {
    const headerObject: HeadersInit = {};
    if (userId) {
        headerObject.Authorization = `Basic ${userId}`;
    }
    const headers = new Headers(headerObject);
    return (await fetch(`${BASE_URL}api/v1/scores/my`, {headers})).json();
}

export type LeaderboardResult = {
    rank: number;
    name: string;
    score: number;
    isMe: boolean;
}

export type LeaderboardResponse = {
    todayResults: LeaderboardResult[];
    thisWeekResults: LeaderboardResult[];
    lastWeekResults: LeaderboardResult[];
    dateString: string;
    firstDayOfWeekString: string;
    firstDayOfLastWeekString: string;
    lastDayOfLastWeekString: string;
}

export const getLeaderboards = async (userId: string | undefined): Promise<LeaderboardResponse> => {
    const headerObject: HeadersInit = {};
    if (userId) {
        headerObject.Authorization = `Basic ${userId}`;
    }
    const headers = new Headers(headerObject);
    return (await fetch(`${BASE_URL}api/v1/scores/leaderboards`, {headers})).json();
}

type UpdateUserNameResponse = {
    success: boolean;
    name: string;
}

export const updateUserName = async (userId: string | undefined, name: string): Promise<UpdateUserNameResponse> => {
    const headerObject: HeadersInit = {
        'Content-Type': 'application/json',
    };
    if (userId) {
        headerObject.Authorization = `Basic ${userId}`;
    }
    const headers = new Headers(headerObject);
    const data = {
        name,
    };
    return (await fetch(`${BASE_URL}api/v1/users/me`, {
        headers,
        method: 'PUT',
        body: JSON.stringify(data)
    })).json();
}
