import React from 'react';
import {DraggableEventHandler} from 'react-draggable';
import {Letter} from './Letter';

type AvailableLettersProps = {
    availableLetters: string[];
    onDragStop: () => void;
    getOnStart: (row: number, column: number, letter: string) => () => void;
    onDrag: DraggableEventHandler;
    droppingCoordinates: number[];
}

export const AvailableLetters = ({availableLetters, onDragStop, getOnStart, onDrag, droppingCoordinates}: AvailableLettersProps) => (
    <fieldset className={'remainingLetters' + (droppingCoordinates[0] === -2 && droppingCoordinates[1] === -2 ? ' highlighted' : '')}>
        <legend>Available Letters</legend>
        <div className="letters-container">
            {availableLetters.map((letter, index) => {
                return (
                    <div
                        key={`${index}-${letter}`}
                        className="available-letter"
                    >
                        <Letter
                            letter={letter}
                            draggable
                            onStart={getOnStart(-1, -1, letter)}
                            onStop={onDragStop}
                            onDrag={onDrag}
                        />
                    </div>
                );
            })}
        </div>
    </fieldset>
);
