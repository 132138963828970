import React from 'react';
import Draggable, {DraggableEventHandler} from 'react-draggable';

type LetterProps = {
    letter: string;
    draggable: boolean;
    gridRow?: number;
    gridColumn?: number;
    onStart: () => void;
    onDrag: DraggableEventHandler;
    onStop: () => void;
}

export const Letter = ({letter, draggable, gridRow, gridColumn, onStart, onDrag, onStop}: LetterProps) => {
    const additionalAttributes: Record<string, number> = {};
    if (typeof gridRow === 'number') {
        additionalAttributes['data-rowindex'] = gridRow;
    }
    if (typeof gridColumn === 'number') {
        additionalAttributes['data-columnindex'] = gridColumn;
    }
    if (draggable) {
        return (
            <Draggable
                onStart={onStart} // only for letters in the grid
                onDrag={onDrag}
                onStop={onStop}
                position={{x: 0, y: 0}}
            >
                <span className="letter-span" {...additionalAttributes}>{letter}</span>
            </Draggable>
        )
    }
    return <span className="letter-span">{letter}</span>;
}
