import React from 'react';

type SolutionProps = {
    label: string;
    puzzle: Puzzle;
}
type Puzzle = {
    Id: string;
    Hints: Record<string, string>;
    Word1: string;
    Word2: string;
    Word3: string;
    Word4: string;
    Word5: string;
};
export const Solution = ({label, puzzle}: SolutionProps) => (
    <div>
        <h3>{label}</h3>
        <table className="grid solution">
            <tbody>
                {[puzzle.Word1, puzzle.Word2, puzzle.Word3, puzzle.Word4, puzzle.Word5].map((word: string, index: number) => {
                    const letters = word.split('');
                    return (
                        <tr key={index}>
                            {letters.map((letter: string, index2: number) => {
                                const isFixed = `${index}-${index2}` in puzzle.Hints || `${index2}-${index}` in puzzle.Hints;
                                return (
                                    <td className={isFixed ? 'cell fixed-cell' : 'cell'} key={index2}><span className="letter-span">{letter}</span></td>
                                )
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
);
