import {ZERO_TO_FOUR} from '../App/constants';
import {words5} from '../dictionaries/dictionary5';


export const localValidate = (attempt: string): string[] => {
    const tests: string[] = [];

    console.log(attempt);

    ZERO_TO_FOUR.forEach((i: number) => {
        const wordArray: string[] = [];
        ZERO_TO_FOUR.forEach((j: number) => {
            wordArray.push(attempt.charAt(i * 5 + j));
        });
        const word = wordArray.join('');
        tests.push(word.indexOf('_') === -1 && words5.has(word) ? 'y' : '');
    });
    return tests;
}
