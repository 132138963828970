import React, {Dispatch, SetStateAction} from 'react';
import {Close} from '../images/Close';
import {trackEvent} from './util';

import {OPENED_MODAL} from '../common/types';

type MenuProps = {
    setOpenedModal: Dispatch<SetStateAction<OPENED_MODAL>>;
    onClose: () => void;
}

type MenuItem = {
    modalKey: OPENED_MODAL;
    icon: string;
    label: string;
}

const menuItems: MenuItem[] = [
    {
        modalKey: OPENED_MODAL.HOW_TO_PLAY,
        icon: 'icon_how_to_play',
        label: 'How to Play',
    },
    {
        modalKey: OPENED_MODAL.SOLUTIONS,
        icon: 'icon_calendar',
        label: 'Past Solutions',
    },
    {
        modalKey: OPENED_MODAL.SETTINGS,
        icon: 'icon_settings',
        label: 'Settings',
    },
    // {
    //     modalKey: OPENED_MODAL.MY_SCORES,
    //     icon: 'icon_star',
    //     label: 'My Scores',
    // },
    {
        modalKey: OPENED_MODAL.LEADERBOARDS,//RANKINGS
        icon: 'icon_rankings',
        label: 'Leaderboards',
    },
    {
        modalKey: OPENED_MODAL.WHAT_IS_NEW,//RANKINGS
        icon: 'icon_message',
        label: 'What\'s New',
    },
    // {
    //     modalKey: OPENED_MODAL.NONE,//BATTLE
    //     icon: 'icon_battle',
    //     label: 'Battle a Friend',
    // },
];
export const Menu = ({setOpenedModal, onClose}: MenuProps) => (
    <div className="modal">
        <div className="modal-header">
            <h2>
                <img width="64" height="64" src="https://wordcrun.ch/img/icon_calendar.png" />
                <span>Menu</span>
            </h2>
            <Close onClick={onClose} />
        </div>
        <div className="modal-content">
            <ul className="menu-list">
                {menuItems.map((menuItem, index) =>
                    menuItem.modalKey === OPENED_MODAL.NONE
                    ? <li key={index}>
                        <img
                            width="128"
                            height="128"
                            src={`https://wordcrun.ch/img/${menuItem.icon}.png`}
                        />
                        <span><div>{menuItem.label}</div><div>(Coming soon!)</div></span>
                    </li>
                    : <li key={index} onClick={() => {
                        trackEvent('click', 'general', 'menu', menuItem.modalKey);
                        setOpenedModal(menuItem.modalKey)}
                        }>
                        <img
                            width="128"
                            height="128"
                            src={`https://wordcrun.ch/img/${menuItem.icon}.png`}
                        />
                        <span>{menuItem.label}</span>
                    </li>
                )}
            </ul>
        </div>
    </div>
);
