import React from 'react';

type ControlsProps = {
    resetGrid: () => void;
    shuffleLetters: () => void;
};

export const Controls = ({resetGrid, shuffleLetters}: ControlsProps) => (
    <div className="controls">
        <fieldset>
            <legend>Controls</legend>
            <div className="control-buttons">
                <button onClick={resetGrid}>Start Over</button>
                <button onClick={shuffleLetters}>Shuffle</button>
            </div>
        </fieldset>
    </div>
);
