const SAVED_DATA_VERSION = 1;
const SAVED_DATA_STORAGE_KEY = 'SAVED_DATA';
type PuzzleState = Record<string, string>;
type SavedData = {
    userId?: string;
    userName?: string;
    version?: number;
    difficulty?: number;
    viewedNewsIndex?: number;
    data?: {
        dateString: string;
        puzzleStates: Record<string, PuzzleState>;
    }
};

/*
get saved data
if saved data does not exist, create it
fetch puzzles, passing userid
if the fetched puzzles date matches saved puzzle date
  populate grids with my puzzle states

*/

const saveObject = (object: SavedData) => {
    localStorage.setItem(SAVED_DATA_STORAGE_KEY, JSON.stringify(object));
}
const createBlankSavedData = (): SavedData => {
    const savedData: SavedData = {
        version: SAVED_DATA_VERSION,
    };
    saveObject(savedData);
    return savedData;
}

export const hasSavedData = (): boolean => {
    return !!localStorage.getItem(SAVED_DATA_STORAGE_KEY);
}

export const getSavedData = (): SavedData => {
    const savedData = localStorage.getItem(SAVED_DATA_STORAGE_KEY);
    if (savedData === null) {
        return createBlankSavedData();
    }
    return JSON.parse(savedData);
}

export const updateUserId = (userId: string): void => {
    if (!userId) {
        return;
    }
    const savedData = getSavedData();
    if (savedData.userId === userId) {
        return;
    }
    savedData.userId = userId;
    saveObject(savedData);
}

export const updatePuzzleData = (puzzleId: string, puzzleDate: string, grid: Record<string, string>): void => {
    if (!puzzleId) {
        return;
    }
    const savedData = getSavedData();
    savedData.data = savedData.data || {
        dateString: '',
        puzzleStates: {},
    };
    // if dates differ, reset puzzleStates;
    if (savedData.data.dateString !== puzzleDate) {
        savedData.data.puzzleStates = {};
    }
    savedData.data.dateString = puzzleDate;
    savedData.data.puzzleStates[puzzleId] = grid;
    saveObject(savedData);
}

export const updateSavedDifficulty = (difficulty: number): void => {
    const savedData = getSavedData();
    savedData.difficulty = difficulty;
    saveObject(savedData);
}

export const updateSavedUserName = (userName: string): void => {
    if (!userName) {
        return;
    }
    const savedData = getSavedData();
    if (savedData.userName === userName) {
        return;
    }
    savedData.userName = userName;
    saveObject(savedData);
}

export const updateViewedNewsIndex = (viewedNewsIndex: number): void => {
    if (!viewedNewsIndex) {
        return;
    }
    const savedData = getSavedData();
    if (savedData.viewedNewsIndex === viewedNewsIndex) {
        return;
    }
    savedData.viewedNewsIndex = viewedNewsIndex;
    saveObject(savedData);
}
