import React from 'react';
import {Check} from '../images/Check';

type ValidationProps = {
    status: string;
    onClick: () => void;
}

export const Validation = ({status, onClick}: ValidationProps) => {
    if (status === 'y') {
        return <Check onClick={onClick} />;
    }
    return null;
}
