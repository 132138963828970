import React, {useEffect, useRef, useState} from 'react';
import {AvailableLetters} from './AvailableLetters';
import {Controls} from './Controls';
import {Grid} from './Grid';
import {Header} from './Header';
import {Victory} from './Victory';
import {DateCalendar} from './DateCalendar';
import {TimeUntil} from './TimeUntil';
import {Circle} from './Circle';
import {SiteUnavailable} from './SiteUnavailable';
import {getBlankGrid, getUnusedLetters as getAvailableLetters, trackEvent} from './util';
import {DraggableEventHandler} from 'react-draggable';
import {getPuzzle, validate} from '../api/api';
import {getSavedData, updateSavedDifficulty, updatePuzzleData, hasSavedData, updateUserId} from '../common/savedData';

import {HowToPlay} from './HowToPlay';
import {Settings} from './Settings';
import {Solutions} from './Solutions';
import {Menu} from './Menu';
import {DefinitionModal} from './DefinitionModal';
import {MyScoresModal} from './MyScoresModal';
import {LeaderboardsModal} from './LeaderboardsModal';
import {WhatIsNewModal} from './WhatIsNewModal';
import {OPENED_MODAL, Stats} from '../common/types';
import {getBaseStats} from '../common/util';
import {localValidate} from '../common/localValidate';

export const App = () => {
    const startedWithSavedData = hasSavedData();
    const savedData = getSavedData();
    const [grid, setGrid] = useState<Record<string, string>>(getBlankGrid());
    const [puzzleId, setPuzzleId] = useState<string>('');
    const [fixedCells, setFixedCells] = useState<Record<string, string>>({});
    const [allLetters, setAllLetters] = useState<string[]>([]);
    const [validation, setValidation] = useState<string[]>(['', '', '', '', '']);
    const [selectedCell, setSelectedCell] = useState<number[]>([0, 0]);
    const [difficulty, setDifficulty] = useState<number>(typeof savedData.difficulty === 'number' ? savedData.difficulty : 9);
    const [puzzleDate, setPuzzleDate] = useState<string>('');
    const [newPuzzlesTimingInfo, setNewPuzzlesTimingInfo] = useState<[Date, number]>([new Date(), -1]);
    const availableLetters = getAvailableLetters(allLetters, grid);
    const [draggedFromCoordinates, setDraggedFromCoordinates] = useState<number[]>([-1, -1]);
    const [droppingCoordinates, setDroppingCoordinates] = useState<number[]>([-1, -1]);
    const [draggingLetter, setDraggingLetter] = useState<string>('');
    const [openedModal, setOpenedModal] = useState<OPENED_MODAL>(startedWithSavedData ? OPENED_MODAL.NONE : OPENED_MODAL.HOW_TO_PLAY);
    const [gameStats, setGameStats] = useState<number[]>([0, 1]);
    const [definitionWord, setDefinitionWord] = useState<string>('');
    const [myStats, setMyStats] = useState<Stats>(getBaseStats());
    const [gameIsDown, setGameIsDown] = useState<boolean>(false);

    const closeModal = () => {
        trackEvent('click', 'general', 'header', 'mondal-close');
        setOpenedModal(OPENED_MODAL.NONE);
    }
    const openMenu = () => {
        trackEvent('click', 'general', 'header', 'menu-open');
        setOpenedModal(OPENED_MODAL.MENU);
    }
    const openWhatIsNewModal = () => {
        trackEvent('click', 'general', 'header', 'new-ribbon');
        setOpenedModal(OPENED_MODAL.WHAT_IS_NEW);
    }

    const updateDifficulty = (newDifficulty: number) => {
        setValidation(['', '', '', '', '']);
        setGrid(getBlankGrid());
        setFixedCells({});
        updateSavedDifficulty(newDifficulty);
        setDifficulty(newDifficulty);
    }

    const difficultySelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const difficulty = parseInt(event.target.value, 10);
        trackEvent('change', 'general', 'difficulty', difficulty);
        updateDifficulty(difficulty);
    }

    const getModal = () => {
        if (openedModal === OPENED_MODAL.HOW_TO_PLAY) {
            return <HowToPlay onClose={closeModal} />;
        }
        if (openedModal === OPENED_MODAL.SOLUTIONS) {
            return <Solutions onClose={closeModal} />;
        }
        if (openedModal === OPENED_MODAL.SETTINGS) {
            return <Settings onClose={closeModal} difficulty={difficulty} setDifficulty={updateDifficulty} />;
        }
        if (openedModal === OPENED_MODAL.MENU) {
            return <Menu onClose={closeModal} setOpenedModal={setOpenedModal} />;
        }
        if (openedModal === OPENED_MODAL.DEFINITION) {
            return <DefinitionModal onClose={closeModal} word={definitionWord} />;
        }
        if (openedModal === OPENED_MODAL.MY_SCORES) {
            return <MyScoresModal onClose={closeModal} />;
        }
        if (openedModal === OPENED_MODAL.LEADERBOARDS) {
            return <LeaderboardsModal onClose={closeModal} />;
        }
        if (openedModal === OPENED_MODAL.WHAT_IS_NEW) {
            return <WhatIsNewModal onClose={closeModal} />;
        }
        return null;
    }

    type SetCellOperation = [number, number, string];
    const setCells = (operations: SetCellOperation[]): void => {
        setGrid(previousGrid => {
            const gridCopy = Object.assign({}, previousGrid);
            operations.forEach(([row, column, letter]: SetCellOperation) => {
                gridCopy[`${row}-${column}`] = letter;
                gridCopy[`${column}-${row}`] = letter;
            });
            const finalOperation = operations[operations.length - 1];
            updatePuzzleData(puzzleId, puzzleDate, gridCopy);
            moveToNextEmptyCell(gridCopy, [finalOperation[0], finalOperation[1]]);
            return gridCopy;
        });
    }
    const applyFixedCells = (cells: Record<string, string>, startingGrid: Record<string, string>): void => {
        const cellsCopy = Object.assign({}, cells);
        const gridCopy = Object.assign({}, startingGrid);
        Object.keys(cells).forEach(rowAndColumn => {
            const row = parseInt(rowAndColumn.charAt(0));
            const column = parseInt(rowAndColumn.charAt(2));
            const letter = cells[rowAndColumn];
            gridCopy[`${row}-${column}`] = letter;
            gridCopy[`${column}-${row}`] = letter;
            cellsCopy[`${column}-${row}`] = letter;
        });
        setFixedCells(cellsCopy);
        setGrid(gridCopy);
        moveToNextEmptyCell(gridCopy, [0, 0]);
    }

    const selectCell = (coordinates: number[]) => {
        setCells([[coordinates[0], coordinates[1], '']]);
        setSelectedCell(coordinates);
    }

    const moveToNextEmptyCell = (grid: Record<string, string>, startCoordinates: number[]) => {
        for (let n = 0; n < 2; n++) {
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 5; j++) {
                    if (n === 0 && i * 5 + j < startCoordinates[0] * 5 + startCoordinates[1]) {
                        continue;
                    }
                    if (grid[`${i}-${j}`] === '') {
                        setSelectedCell([i, j]);
                        return;
                    }
                }
            }
        }
    }

    const resetGrid = () => {
        const operations: SetCellOperation[] = [];
        for(let row = 4; row >= 0; row--) {
            for (let column = 4; column >= 0; column--) {
                if (column >= row) {
                    if (!(`${row}-${column}` in fixedCells)) {
                        operations.push([row, column, '']);
                    }
                }
            }
        }
        setCells(operations);
        setValidation(['', '', '', '', '']);
    };

    const getOnStart = (row: number, column: number, letter: string) => () => {
        setDraggedFromCoordinates([row, column]);
        setDraggingLetter(letter);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDrag: DraggableEventHandler = (event: any) => {
        const x = event.clientX || event.changedTouches[0].clientX;
        const y = event.clientY || event.changedTouches[0].clientY;
        const target = document.elementFromPoint(x, y) as HTMLElement;
        if (!target) {
            return;
        }
        
        const rowIndex = target.dataset['rowindex'];
        const columnIndex = target.dataset['columnindex'];
        if (typeof rowIndex !== 'undefined' && typeof columnIndex !== 'undefined') {
            const rowIndexNumber = parseInt(rowIndex, 10);
            const columnIndexNumber = parseInt(columnIndex, 10);
            if (rowIndexNumber !== droppingCoordinates[0] || columnIndexNumber !== droppingCoordinates[1]) {
                setDroppingCoordinates([rowIndexNumber, columnIndexNumber]);
                return;
            }
        } else if (target.classList.contains('letters-container') || target.classList.contains('remainingLetters')) {
            if (droppingCoordinates[0] !== -2 || droppingCoordinates[1] !== -2) {
                setDroppingCoordinates([-2, -2]);
                return;
            }
        }
    }

    const completeDragAndDrop = () => {
        setDraggedFromCoordinates([-1, -1]);
        setDroppingCoordinates([-1, -1]);
        setDraggingLetter('');
    };

    const onDragStop = () => {
        // if it was really just a click and not a drag
        if (droppingCoordinates[0] === -1 && droppingCoordinates[1] === -1) {
            if (draggedFromCoordinates[0] === -1 && draggedFromCoordinates[1] === -1) {
                // clicked from the available letters
                setCells([[selectedCell[0], selectedCell[1], draggingLetter]]);
            } else {
                // clicked in the grid
                setCells([[draggedFromCoordinates[0], draggedFromCoordinates[1], '']]);
            }
            completeDragAndDrop();
            return;
        }

        // if they tried to drop on a fixed cell, don't update grid;
        if (`${[droppingCoordinates[0]]}-${droppingCoordinates[1]}` in fixedCells) {
            completeDragAndDrop();
            return;
        }

        // if they dragged from the grid
        const operations: SetCellOperation[] = [];
        if (draggedFromCoordinates[0] !== -1 && draggedFromCoordinates[1] !== -1) {
            if (droppingCoordinates[0] === -2 && droppingCoordinates[1] === -2) {
                // if they dragged from grid to 'Available Letters', clear that grid cell
                setCells([[draggedFromCoordinates[0], draggedFromCoordinates[1], '']]);
                completeDragAndDrop();
                return;
            }
            // if they dropped on a letter, put they letter the dropped on, into the cell they dragged from (swap)
            const previousLetter = grid[`${[droppingCoordinates[0]]}-${droppingCoordinates[1]}`];
            if (previousLetter === '') {
                operations.push([draggedFromCoordinates[0], draggedFromCoordinates[1], '']);
            } else {
                operations.push([draggedFromCoordinates[0], draggedFromCoordinates[1], previousLetter]);
            }
        }
        operations.push([droppingCoordinates[0], droppingCoordinates[1], draggingLetter]);
        setCells(operations);
        completeDragAndDrop();
    }

    const shuffleLetters = () => {
        const allLettersCopy = [...allLetters].sort(() => 0.5 - Math.random());
        setAllLetters(allLettersCopy);
    }

    const getAttempt = (): string => {
        const gridFlattened: string[] = [];
        [0, 1, 2, 3, 4].forEach(row => {
            [0, 1, 2, 3, 4].forEach(column => {
                gridFlattened.push(grid[`${row}-${column}`] || '_');
            });
        });
        return gridFlattened.join('');
    }

    useEffect(() => {
        (async () => {
            let puzzleData;
            try {
                puzzleData = await getPuzzle(difficulty, savedData.userId);
            } catch (e) {
                setGameIsDown(true);
                return;
            }
            const {
                puzzleId,
                fixedCells,
                lettersRandom,
                date,
                secondsUntilNewPuzzles,
                gameStats,
                userId,
            } = puzzleData;
            if (userId) {
                updateUserId(userId);
            }
            setPuzzleId(puzzleId);
            if (savedData.data && savedData.data.puzzleStates && puzzleId in savedData.data.puzzleStates) {
                applyFixedCells(fixedCells, savedData.data.puzzleStates[puzzleId]);
            } else {
                applyFixedCells(fixedCells, getBlankGrid());
            }
            setAllLetters(lettersRandom);
            setNewPuzzlesTimingInfo([new Date(), secondsUntilNewPuzzles]);
            setPuzzleDate(date);
            setGameStats(gameStats);
            
            const attempt = getAttempt();
            if (attempt !== '_________________________') {
                setValidation(localValidate(attempt));
            }
        })();
    }, [difficulty]);

    useEffect(() => {
        (async () => {
            const attempt = getAttempt();
            if (attempt === '_________________________') {
                return;
            }
            const localValidateResponse = localValidate(attempt);
            setValidation(localValidateResponse);
            const victory = localValidateResponse.length === 5 && localValidateResponse.every(value => value === 'y');
            if (victory) {
                let validateResponse;
                try {
                    validateResponse = await validate(attempt, savedData.userId, puzzleId);
                } catch (e) {
                    setGameIsDown(true);
                    return;
                }
                if (validateResponse.success) {
                    const {userId, stats} = validateResponse;
                    if (stats) {
                        setMyStats(stats);
                    }
                    if (userId) {
                        updateUserId(userId);
                    }
                }
            }
        })();
    }, [grid])

    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (divRef.current !== null) {
            divRef.current.focus();
        }
     }, []);

     const setLookupWord = (index: number) => {
        const word = `${grid[index + '-' + 0]}${grid[index + '-' + 1]}${grid[index + '-' + 2]}${grid[index + '-' + 3]}${grid[index + '-' + 4]}`;
        setDefinitionWord(word);
        setOpenedModal(OPENED_MODAL.DEFINITION);
     }

    const victorious = validation.every(item => item === 'y');

    const modal = getModal();
    const winPercent = Math.floor(100 * (gameStats[0]) / gameStats[1]);

    if (gameIsDown) {
        return (
            <SiteUnavailable />
        );
    }

    return (
        <div
            className="app-container"
            ref={divRef}
            tabIndex={0}
            onKeyDown={(event) => {
                if (openedModal !== OPENED_MODAL.NONE) {
                    return;
                }
                const letter = (event.key === 'Backspace' || event.key === 'Delete' ? '' : event.key).toLowerCase();
                if (letter === '' || availableLetters.indexOf(letter) !== -1) {
                    setCells([[selectedCell[0], selectedCell[1], letter]]);
                }
            }}
        >
            <Header openMenu={openMenu} openWhatIsNewModal={openWhatIsNewModal} viewedNewsIndex={savedData.viewedNewsIndex} />
            {victorious
                ? <Victory
                    difficulty={difficulty}
                    puzzleDate={puzzleDate}
                    updateDifficulty={updateDifficulty}
                    gameStats={gameStats}
                    myStats={myStats}
                />
                : null
            }
            <Grid
                fixedCells={fixedCells}
                grid={grid}
                validation={validation}
                selectedCell={selectedCell}
                selectCell={selectCell}
                getOnStart={getOnStart}
                onDragStop={onDragStop}
                onDrag={onDrag}
                droppingCoordinates={droppingCoordinates}
                draggedFromCoordinates={draggedFromCoordinates}
                victorious={victorious}
                setLookupWord={setLookupWord}
            />
            {victorious === false && <>
                <AvailableLetters
                    availableLetters={availableLetters}
                    onDragStop={onDragStop}
                    getOnStart={getOnStart}
                    onDrag={onDrag}
                    droppingCoordinates={droppingCoordinates}
                />
                <Controls resetGrid={resetGrid} shuffleLetters={shuffleLetters} />
            </>}
            <div className="controls">
                <fieldset>
                    <legend>This Puzzle</legend>
                    <div className="date-and-difficulty">
                        <div><DateCalendar date={puzzleDate} /></div>
                        <div>
                            <select onChange={difficultySelectOnChange} value={difficulty}>
                                <option value={9}>Easy</option>
                                <option value={6}>Medium</option>
                                <option value={3}>Hard</option>
                                <option value={0}>Brutal</option>
                            </select>
                        </div>
                        <div>
                            <Circle fillPercent={winPercent} />
                            <p>Solved by {gameStats[0]} out of {gameStats[1]} players.</p>
                        </div>
                    </div>
                    <p>New puzzles arrive in: <TimeUntil timeInfo={newPuzzlesTimingInfo} /></p>
                </fieldset>
            </div>
            {modal}
        </div>
    );
}
